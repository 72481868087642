import data from './BiosAndBootMenuKeys.json';

import './BiosAndBootMenuKeys.css';

const BiosAndBootMenuKeys = () => {
	let headings = Object.keys(data[0].content[0]);
	return (
		<div className='tables-container'>
			{data.map((element) => (
				<div className='individual-table-container'>
					<p className='table-container-header'>{element.name}</p>
					<table className='table table-bordered'>
						<thead>
							<tr className='table-unit'>
								{headings.map((heading) => (
									<th className='table-unit'>{heading}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{element.content.map((item) => (
								<tr className='table-unit'>
									{headings.map((heading) => (
										<td className='table-unit'>{item[heading]}</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			))}
		</div>
	);
};

export default BiosAndBootMenuKeys;

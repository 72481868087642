import './App.css';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import NavBar from './Components/HeaderAndFooter/NavBar';
import HomePage from './Components/Pages/HomePage/HomePage';
import Contact from './Components/Pages/Contact/Contact';
import HowToBoot from './Components/Pages/HelpAndSupport/HowToBootPage';
import Footer from './Components/HeaderAndFooter/Footer';
import PCs from './Components/Pages/HelpAndSupport/HowToBootData.json';
import OSs from './Components/Pages/HelpAndSupport/LinuxSupport/LinuxSupport.json';
import ItemPage from './Components/Pages/HelpAndSupport/ItemPage/ItemPage';
import UnderConstructionPage from './Components/Pages/UnderConstruction/UnderConstructionPage';
import ResetBIOSPassword from './Components/Pages/HelpAndSupport/ResetBIOS/ResetBIOS';
import ContactPage from './Components/Pages/Contact/ContactPage';
import LinuxSupport from './Components/Pages/HelpAndSupport/LinuxSupport/LinuxSupport';
import BiosAndBootMenuKeys from './Components/Pages/HelpAndSupport/BiosAndBootMenuKeys/BiosAndBootMenuKeys';

function App() {
	const [routes, setRoutes] = useState([]);
	const [firstLoad, setFirstLoad] = useState(true);

	const addRoute = (currPath = '', path, element) => {
		let newRList = routes;
		newRList.push(<Route exact path={currPath + path} element={element} />);

		setRoutes(newRList);
		newRList = null;
	};

	if (firstLoad) {
		setFirstLoad(false);

		addRoute('/', 'howtoboot', <HowToBoot addRoute={addRoute} />);
		addRoute('', '/contact', <Contact />);
	}

	return (
		<Router>
			<div className='App'>
				<NavBar />

				<Routes>
					{PCs.map((pc, index) => {
						return (
							<Route
								key={index}
								exact
								path={'/howtoboot/' + pc.name.toLowerCase().replaceAll(' ', '')}
								element={<ItemPage entry={pc} />}
							/>
						);
					})}
					{OSs.map((os, index) => {
						return (
							<Route
								key={index}
								exact
								path={
									'/linuxsupport/' + os.name.toLowerCase().replaceAll(' ', '')
								}
								element={<ItemPage entry={os} />}
							/>
						);
					})}
					<Route
						exact
						path='/howtoboot'
						element={<HowToBoot addRoute={addRoute} />}
					/>
					<Route
						exact
						path='/linuxsupport'
						element={<LinuxSupport addRoute={addRoute} />}
					/>
					<Route
						path='/biosandbootmenukeys'
						element={<BiosAndBootMenuKeys />}
					/>
					<Route path='/resetbiospassword' element={<ResetBIOSPassword />} />
					<Route path='/contact' element={<ContactPage />} />
					<Route exact path='/home' element={<HomePage />} />
					<Route path='/' element={<HomePage />} />

					<Route path='*' element={<UnderConstructionPage />} />
				</Routes>

				<Footer />
			</div>
		</Router>
	);
}

export default App;

import './HomePage.css';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';

export default function HomePage() {
	const [speed, setSpeed] = useState(5);

	function coinSpin() {
		if (speed > 0.1) {
			document.getElementById('coin').style.animationDuration = `${speed / 2}s`;
			setSpeed(speed / 2);
		}
	}

	useEffect(() => {
		document.getElementById('coin').style.animationDuration = `5s`;

		setSpeed(5);
	}, []);

	return (
		<div>
			<Container expand='lg'>
				<div className='mainBox'>
					<div className='row mt-5'>
						<div className='col-sm'>
							<h1 className='display-4'> Hi there :)</h1>
							<br />

							<p>
								Welcome to We Are Linux, <br />
								We are a small software business based in Europe, Our goal is to
								bring high quality software for a low price.
								<br />
								<br />
								We will never sell any software that we do not believe in or use
								for ourselves.
								<br />
								Before bringing any software to the market we test it several
								times to make sure it is safe and working properly.
							</p>
						</div>

						<div className='col-sm' id='coinId'>
							<div
								id='coin'
								className='coin'
								// style={{ animation: 'spin 5s linear infinite' }}
								onClick={coinSpin}
							></div>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
}

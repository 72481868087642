import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PCs from './../../Pages/HelpAndSupport/HowToBootData.json';
import OSs from './../../Pages/HelpAndSupport/LinuxSupport/LinuxSupport.json';
import './SearchBar.css';

const SearchBar = () => {
	const navigate = useNavigate();

	const Data = PCs.map((element) => {
		return {
			name: element.name,
			url: `/howtoboot/${element.name.toLowerCase().replaceAll(' ', '')}`,
		};
	}).concat(
		OSs.map((element) => {
			return {
				name: element.name,
				url: `/linuxsupport/${element.name.toLowerCase().replaceAll(' ', '')}`,
			};
		})
	);

	const [searchValue, setSearchValue] = useState('');
	const [tempData, setTempData] = useState([]);

	const onChangeAction = (event) => {
		setTempData(
			Data.filter((post) => {
				if (event.target.value === '') {
					return null;
				} else if (
					post.name.toLowerCase().includes(event.target.value.toLowerCase())
				) {
					return post;
				}
				return null;
			})
		);
	};
	return (
		<div className='searchbar-container'>
			<input
				className='searchbar-input'
				placeholder='Search...'
				onChange={(event) => {
					setSearchValue(event.target.value);
					onChangeAction(event);
				}}
				value={searchValue}
			/>
			{tempData.length ? (
				<div className='searchbar-results'>
					{tempData.map((post, index) => (
						<div
							className='searchbar-box'
							key={index}
							onClick={() => {
								setSearchValue('');
								setTempData([]);
								navigate(post.url);
							}}
						>
							<p align={'left'} className='result'>
								{post.name}
							</p>
						</div>
					))}
				</div>
			) : (
				''
			)}
		</div>
	);
};
export default SearchBar;

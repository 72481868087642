import ItemPage from '../ItemPage/ItemPage';
import Data from './../OtherArticlesData.json';

const ResetBIOSPassword = () => {
	const resetBiosData = Data.filter(
		(item) => item.name === 'Reset BIOS Password'
	)[0];
	return (
		<>
			<ItemPage entry={resetBiosData} />
		</>
	);
};

export default ResetBIOSPassword;

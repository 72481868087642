import React from 'react'
import './Footer.css';



export default function Footer() {
    var today = new Date();


var date = today.getFullYear();
return (
  <div className='FooterWrap'>
    <p></p>
  <p className='Footer'>Copyright © {date} We Are Linux, All rights reserved. </p>
  </div>
  )
}




import Contact from './Contact';

import './ContactPage.css';

const ContactPage = () => {
	return (
		<>
			<div className='contact-container'>
				<p className='contact-header'>Contact us</p>
				<p className='contact-body'>
					Please use this form to contact us. We will get back to you within 48
					hours.
				</p>
				<Contact />
			</div>
		</>
	);
};

export default ContactPage;

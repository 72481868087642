import React from 'react';
import './ItemPage.css';

const ItemPage = ({ entry }) => {
	const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
	const stringTemplateParser = (expression) => {
		let text = expression.match(templateMatcher);
		const value = text ? text[0].replace('{{', '').replace('}}', '') : null;
		return value;
	};
	return (
		<div>
			<div>
				<p className='page-title'>{entry.name}</p>
			</div>
			<div className='page-body'>
				<div
					className={`description-column description-column-${
						entry.Video ? 'video' : 'no-video'
					}`}
				>
					{entry.text.map((element, index) => (
						<div key={index} className='instruction-entry'>
							<div
								className={`instruction-container instruction-container-${
									stringTemplateParser(element, entry) ? 'image' : 'no-image'
								}`}
							>
								{/^\/\/\//.test(element) ? (
									<div className='code'>
										<p align={'left'} className='code-text'>
											{element
												.replace(templateMatcher, '')
												.replace(/^\/\/\//, '')}
										</p>
									</div>
								) : (
									<p align={'left'} className='new-p'>
										{element.replace(templateMatcher, '')}
									</p>
								)}
							</div>
							{stringTemplateParser(element, entry) ? (
								<div className='image-container'>
									<img
										src={entry[stringTemplateParser(element, entry)]}
										alt={'computer'}
										className='instruction-image'
									/>
								</div>
							) : (
								<></>
							)}
						</div>
					))}
				</div>
				{entry.Video && (
					<div className='video-column'>
						<video
							src={entry.Video}
							width='100%'
							height='300'
							controls='controls'
							type='video/mp4'
						/>
					</div>
				)}
			</div>
			{entry.text2 && (
				<div className='page-body'>
					<div
						className={`description-column description-column-${
							entry.Video2 ? 'video' : 'no-video'
						}`}
					>
						{entry.text2.map((element, index) => (
							<div key={index} className='instruction-entry'>
								<div
									className={`instruction-container instruction-container-${
										stringTemplateParser(element, entry) ? 'image' : 'no-image'
									}`}
								>
									{/^\/\/\//.test(element) ? (
										<div className='code'>
											<p align={'left'} className='code-text'>
												{element
													.replace(templateMatcher, '')
													.replace(/^\/\/\//, '')}
											</p>
										</div>
									) : (
										<p align={'left'} className='new-p'>
											{element.replace(templateMatcher, '')}
										</p>
									)}
								</div>
								{stringTemplateParser(element, entry) ? (
									<div className='image-container'>
										<img
											src={entry[stringTemplateParser(element, entry)]}
											alt={'computer'}
											className='instruction-image'
										/>
									</div>
								) : (
									<></>
								)}
							</div>
						))}
					</div>
					{entry.Video2 && (
						<div className='video-column'>
							<video
								src={entry.Video2}
								width='100%'
								height='300'
								controls='controls'
								type='video/mp4'
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ItemPage;

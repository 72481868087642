import React from 'react';
import ItemCard from './../ItemCard/ItemCard';
import './../HowToBootPage.css';
import { Container } from 'react-bootstrap';
import Data from './LinuxSupport.json';
import ItemPage from './../ItemPage/ItemPage';

function LinuxSupport({ addRoute }) {
	const curr = '/linuxsupport/';

	return (
		<div>
			<Container>
				<div className='GridSetting'>
					{Data.map((item) => {
						let card = <ItemCard BrandName={item.name} />;
						addRoute(
							curr,
							item.name.toLowerCase().replaceAll(' ', ''),

							<ItemPage entry={item} />
						);
						return card;
					})}
				</div>
			</Container>
		</div>
	);
}

export default LinuxSupport;

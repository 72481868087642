import { useNavigate } from 'react-router-dom';
import './Card.css';

function ItemCard({ BrandName, imgSrc }) {
	let navigate = useNavigate();

	return (
		<div
			className={`Card ${imgSrc ? '' : 'Imageless'}`}
			onClick={() =>
				navigate(`./${BrandName.toLowerCase().replaceAll(' ', '')}`)
			}
		>
			<div className='BrandName'>{BrandName}</div>

			{imgSrc ? <img alt={'icon'} src={imgSrc} className='CardImage' /> : <></>}
		</div>
	);
}

export default ItemCard;

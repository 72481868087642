import './UnderConstructionPage.style.css';

const UnderConstructionPage = () => {
	return (
		<>
			<div className='under-construction-body'>
				<p className='under-construction-text'>
					Unfortunately, the page is currently under construction.
				</p>
				<img
					src='/Images/underconstruction.png'
					alt={'Under construction'}
					className='under-construction-image'
				/>
			</div>
		</>
	);
};

export default UnderConstructionPage;

import { DropdownButton } from 'react-bootstrap';
import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './NavBar.css';
import SearchBar from './SearchBar/SearchBar';

export default function NavBar() {
	let navigate = useNavigate();

	return (
		<div>
			<div className='NavWrap'>
				<Navbar className='Navbar' bg='transparent' expand='lg'>
					<Container>
						<div className='logoDiv'>
							<img src={'/Images/icon.png'} alt='Logo' className='logo' />
						</div>
						<Navbar.Brand className='navBrand'>We Are Linux</Navbar.Brand>
						<Navbar.Toggle aria-controls='basic-navbar-nav' />
						<Navbar.Collapse id='basic-navbar-nav'>
							<Nav className='me-auto'>
								<Nav onClick={() => navigate('/home')} className='navText'>
									Home
								</Nav>

								<DropdownButton
									className='navText navbar-nav'
									title='Help & Support'
									id='basic-dropdown'
								>
									<Dropdown.Item onClick={() => navigate('/howtoboot')}>
										How to boot from our USB/DVD
									</Dropdown.Item>
									<Dropdown.Item onClick={() => navigate('/resetbiospassword')}>
										Reset BIOS password
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => navigate('/resetwindowspassword')}
									>
										Reset Windows Password
									</Dropdown.Item>
									<Dropdown.Item onClick={() => navigate('/fixwindows')}>
										Fix Windows
									</Dropdown.Item>
									<Dropdown.Item onClick={() => navigate('/linuxsupport')}>
										Linux Support
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => navigate('/biosandbootmenukeys')}
									>
										Boot and Bios Menu Keys
									</Dropdown.Item>
								</DropdownButton>

								<Nav onClick={() => navigate('/contact')} className='navText'>
									Contact Us
								</Nav>
								<Nav onClick={() => navigate('/shop')} className='navText'>
									Shop
								</Nav>
							</Nav>

							<SearchBar />
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</div>
		</div>
	);
}

import React from 'react';
import ItemCard from './ItemCard/ItemCard';
import './HowToBootPage.css';
import { Container } from 'react-bootstrap';
import Data from './HowToBootData.json';
import { useNavigate } from 'react-router-dom';
import ItemPage from './ItemPage/ItemPage';

function HowToBoot({ addRoute }) {
	const curr = '/howtoboot/';

	let navigate = useNavigate();
	function clickToContact() {
		navigate('/contact');
	}

	return (
		<div>
			<h4 className='subTitle'>
				{' '}
				Choose your computer brand to see a step-by-step instructions. if you
				cannot find it click
				<div className='clickToContact' onClick={clickToContact}>
					{' '}
					here{' '}
				</div>{' '}
				to contact us - we will add it.
			</h4>

			<Container>
				<div className='GridSetting'>
					{Data.map((item) => {
						let card = <ItemCard BrandName={item.name} imgSrc={item.imgSrc} />;
						addRoute(
							curr,
							item.name.toLowerCase().replaceAll(' ', ''),

							<ItemPage entry={item} />
						);
						return card;
					})}
				</div>
			</Container>
		</div>
	);
}

export default HowToBoot;

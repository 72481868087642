import React from 'react';
// import { useForm, ValidationError } from '@formspree/react';
import { useForm } from '@formspree/react';
import './Contact.css';
import { useState } from 'react';

function ContactForm() {
	const [name, setName] = useState('');
	// const [submitted, setSubmitted] = useState(false);
  const [state, handleSubmit] = useForm("moqrydgr");
  if (state.succeeded) {
		return (
			<div className='contactReply'>
				<h2>Thank you {name}!</h2>
				<br />
				<div>We'll be in touch soon :)</div>
			</div>
		);
  }

  return (
	<div className='FormWrap'>
      <form onSubmit={handleSubmit} className='ContactForm'>


	  <div className='row'>
 					<div className='col'>
					 	<input
							type='text'
							class='form-control contactInput'
							placeholder='Name'
							name='name'
							required
							onChange={(event) => {
								setName(event.target.value);
							}}
						/>
					</div>
					<div className='col'>
						<input
							type='text'
							class='form-control contactInput'
							placeholder='Order number (if existent)'
							name='order number'
						/>
					</div>
				</div>
				<div>
					<input
						type='email'
						class='form-control contactInput'
						placeholder='Email address'
						id="email"
						name='email'
						required
					/>
				</div>
				<div>
					<textarea
						rows='5'
						placeholder='Write the details of your request.'
						id="message"
						class='form-control contactInput'
						name='message'
						required
					/>
				</div>


      <button type="submit" disabled={state.submitting}  class='btn rounded-pill btn-light contactInput'>
        Submit
      </button>
    </form>
	</div>
  );
}


export default ContactForm;